













import Vue, { Component as VueComponent } from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import newsFetcher, { IArticle } from '../news-fetcher'

@Component({
  name: 'news-ticker',
})
export default class NewsTicker extends Vue {
  articles: IArticle[] = []
  loading = true

  async mounted () {
    this.articles = (await newsFetcher.fetch()).slice(0, 3)
    this.loading = false
  }

  isNewArticle (article: IArticle) {
    const elapsed = new Date().getTime() - article.timestamp
    return elapsed < 1000 * 60 * 60 * 24
  }

  articleStyle (article: IArticle) {
    if (this.isNewArticle(article)) {
      return {
        'font-weight': 'bold',
      }
    } else {
      return {}
    }
  }
}
