


























import { Page } from '../page'
import Vue, { Component as VueComponent } from 'vue'
import { minBy, last } from '../util'
import Component from 'vue-class-component'
import { ArtData } from '../models/art'
import { UserData } from '../models/user'
import { buildLayers } from '../models/layer'
import { GyroInputController } from '../gyro-input-controller'

import device from '../device'
import db from '../db'

import ArtList from './ArtList.vue'
type Art = typeof ArtData['types']['stored']

@Component({
  name: 'latest-art-list',
  components: {
    ArtList,
  },
})
export default class LatestArtList extends Vue {
  device = device
  arts: Art[] = []
  loading = true
  gyroInputController?: GyroInputController
  oldestArtTimestampInPage = (new Date()).getTime()
  pageGroupedArts: Art[][] = []
  page = 0
  numberOfArtsInPage = 6
  oldestArtId: string = ''

  async created () {
    this.gyroInputController = new GyroInputController()
  }

  beforeDestroy () {
    if (this.gyroInputController) {
      this.gyroInputController.unsubscribe()
    }
  }

  async loadNext () {
    this.page++
    this.loading = true

    const query = db.collection('arts')
      .where('publishedAt', '>', 0)
      .where('publishedAt', '<', this.oldestArtTimestampInPage)
      .orderBy('publishedAt', 'desc')
      .limit(this.numberOfArtsInPage)

    const arts = await this.$firelink.query<typeof ArtData>(query).fetch()
    this.arts = arts.docs.map((doc) => doc.mapped)
    this.pageGroupedArts.push(this.arts)

    const oldestArtInPage = minBy(this.arts, (art) => art.publishedAt)

    if (oldestArtInPage) {
      this.oldestArtTimestampInPage = oldestArtInPage.publishedAt
    }

    this.loading = false
  }

  async loadPrev () {
    this.loading = true

    setTimeout(() => {
      this.page--
      this.pageGroupedArts.pop()
      const arts = last(this.pageGroupedArts)!
      this.arts = arts

      this.oldestArtTimestampInPage = minBy(this.arts, (art) => {
        return art.publishedAt
      })!.publishedAt
      this.loading = false
    }, 200)
  }

  async mounted () {
    await this.loadNext()

    const oldestArtQuery = db.collection('arts')
      .where('publishedAt', '>', 0)
      .orderBy('publishedAt', 'asc')
      .limit(1)

    const oldestArt = last((await this.$firelink.query<typeof ArtData>(oldestArtQuery).fetch()).docs)

    if (oldestArt) {
      this.oldestArtId = oldestArt.id
    }
  }

  get isLastPage () {
    return this.arts.map((a) => a.id).includes(this.oldestArtId)
  }

  get isTablet () {
    return device.isTablet
  }

  get isMobile () {
    return device.isMobile
  }

  get calculatedPaneSize () {
    const contentHeight = 86
    if (this.isMobile) {
      const itemSize = Math.min(window.innerWidth, window.innerHeight) - 60
      return {
        width: itemSize,
        height: itemSize + contentHeight,
      }

    } else {
      const column = 3
      const itemSize = Math.min(window.innerWidth / column, 374) - (20 * column)
      return {
        width: itemSize,
        height: itemSize + contentHeight,
      }
    }
  }
}
