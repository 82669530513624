import Vue, { ComponentOptions, Component } from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { sortBy } from './util'
import { IPageComponent } from './page'
import { first } from 'rxjs/operators'

import identityManager from './identity-manager'

export interface IRoutable {
  path: string;
  $options: ComponentOptions<Vue>;
}

const context = require.context('./components/pages', false, /\.vue$/)
const pageDefinitions: { [name: string]: { authRequired: boolean } } = {}

const routes: RouteConfig[] = sortBy(
  context.keys().map(key => {
    const PageComponent = context<{ default: IPageComponent }>(key).default
    const page = new PageComponent()
    if (page.$options.name) {
      pageDefinitions[page.$options.name] = {
        authRequired: page.authRequired,
      }
    }
    return {
      path: page.path,
      name: page.$options.name,
      component: PageComponent,
      $options: page.$options,
    }
  }),
  'path'
).reverse()

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  const authorized = !!identityManager.currentUserInfo
  if (to.name) {
    const definition = pageDefinitions[to.name]
    if (definition.authRequired) {
      if (authorized) {
        return next()
      }
      if (localStorage.getItem('identity-cache')) {
        return identityManager.currentUserInfoStream
          .pipe(first())
          .subscribe(() => next())
      }
      return next({ name: 'auth' })
    }
  }
  next()
})

export default router
