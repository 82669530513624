import { ModelFactory, Reference, ITypedDocumentReference } from '../vue-typed-firestore'
import { LayerData } from './layer'
import { UserData } from './user'

export type ArtProcessStatus = 'initiate' | 'in-progress' | 'completed' | 'failed'
export type ArtProcessSubject = 'generic' | 'extract' | 'store-data' | 'encode-video' | 'store-video' | 'store-metadata' | 'completed'
export type LocalLayerData = typeof LayerData['types']['local'] & typeof LayerData['types']['base']
export type RemoteLayerData = typeof LayerData['types']['remote'] & typeof LayerData['types']['base']
export interface IVideoLocation {
  url: string
}
export interface IArtVideos {
  main: IVideoLocation
}

export const ArtData = ModelFactory<{
  title: string,
  backgroundColor: string,
}, {
  layers: LocalLayerData[],
}, {
  layers: RemoteLayerData[],
  status: ArtProcessStatus,
  subject: ArtProcessSubject,
  errors?: IArtError[],
  numberOfLayers: number,
  user: ITypedDocumentReference<typeof UserData>,
  publishedAt: number,
  videos: IArtVideos,
}>()

export interface IArtError {
  occurredAt: number
  message: string
}
