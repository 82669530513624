






import Vue, { Component as VueComponent } from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import * as query from 'query-string'

@Component({
  name: 'tweet-button',
})
export default class TweetButton extends Vue {
  @Prop({ default: {}, type: Object })
  options!: {}

  get intentLink () {
    const q = query.stringify(this.options)
    return `https://twitter.com/intent/tweet?${q}`
  }
}
