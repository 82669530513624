



















import Vue, { Component as VueComponent } from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop } from 'vue-property-decorator'
import { ArtData } from '../models/art'
import { GyroInputController } from '../gyro-input-controller'
import ArtsRenderer, { shared as sharedRenderer } from '../arts-renderer'
import db from '../db'
import device from '../device'

import 'vue-router'

interface ILayerAppearance {
  visible: boolean;
}

type Art = typeof ArtData['types']['stored'] | typeof ArtData['types']['local'];

@Component({
  name: 'art-list',
})
export default class ArtList extends Vue {
  private mouseOffset = { x: 0, y: 0 };
  private lastRenderedArts: Art[] = [];
  private controlModeTooltipVisibility = false;

  @Prop({ default: 0.3, type: Number })
  private rotateMagnificate!: number;

  @Prop({ default: false, type: Boolean })
  private autoRotateModel!: boolean;

  @Prop({ default: () => sharedRenderer, type: ArtsRenderer })
  private renderer!: ArtsRenderer;

  @Prop({ default: [], type: Array })
  private arts!: Art[];

  @Prop({ default: 300, type: Number })
  private itemSize!: number;

  @Prop({ default: 1.0, type: Number })
  private itemScale!: number;

  @Prop({ default: 'default', type: String })
  private paneAlign!: 'default' | 'center';

  @Prop({ default: undefined, type: Object })
  private paneSize!: { width: number; height: number };

  @Prop({ default: undefined, type: Object })
  private paneContentSize!: { width: number; height: number };

  @Prop({ default: undefined, type: Object })
  private gyroInputController!: GyroInputController;

  @Prop({ default: 30, type: Number })
  private gutter!: number;

  @Prop({ default: true, type: Boolean })
  private showOverlay!: boolean;

  @Prop({ default: false, type: Boolean })
  private showActions!: boolean;

  @Prop({ default: true, type: Boolean })
  private showBase!: boolean;

  @Prop({ default: false, type: Boolean })
  private adjuster!: boolean;

  @Prop({ default: () => [], type: Array })
  private layerAppearances!: ILayerAppearance[];

  @Prop({ default: false, type: Boolean })
  private rerenderOnlyActuallyChanges!: boolean;

  mounted() {
    window.addEventListener('resize', this.handleResize)

    this.handleArtsChange(this.arts)

    const container = this.$refs.canvasContainer
    if (container instanceof HTMLDivElement) {
      container.appendChild(this.renderer.domElement)
      this.updateRenderer()
      this.renderer.startRenderingLoop()
    }

    if (this.showActions && (device.isMobile || device.isTablet)) {
      setTimeout(() => {
        this.controlModeTooltipVisibility = true
        setTimeout(() => {
          this.controlModeTooltipVisibility = false
        }, 1500)
      }, 500)
    }
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  }

  adjustGyroInput() {
    this.renderer.resetControlInputBasePoint()
  }

  get paneStyle() {
    const size = this.paneSize || {
      width: this.itemSize,
      height: this.itemSize,
    }
    return {
      width: `${size.width}px`,
      height: `${size.height}px`,
      margin: `${this.gutter}px`,
    }
  }

  get paneContentStyle() {
    const size = this.paneContentSize || {
      width: this.itemSize,
      height: this.itemSize,
    }
    return {
      width: `${size.width}px`,
      height: `${size.height}ppx`,
    }
  }

  get paneListStyle() {
    switch (this.paneAlign) {
      case 'center':
        return { 'justify-content': 'center' }
      default:
        return {}
    }
  }

  get listClasses() {
    const embed = 'embed' in this.$route.query
    return embed ? ['embed'] : device.platformClasses
  }

  get controlModeDescription() {
    if (this.gyroInputController) {
      return '操作モード：傾きセンサー'
    } else {
      return '操作モード：指でタッチ'
    }
  }

  private handleMouseout(evt: MouseEvent) {
    this.resetModelViewpoint()
  }

  private resetModelViewpoint() {
    const context = this.$refs.context

    if (!(context instanceof HTMLDivElement)) {
      return
    }

    this.renderer.updateModelViewpoint({
      x: context.scrollWidth / 2,
      y: context.scrollHeight / 2,
    })
  }

  private handleMousemove(evt: MouseEvent) {
    if (device.isTablet || device.isMobile) {
      return
    }

    const context = this.$refs.context

    if (!(context instanceof HTMLDivElement)) {
      return
    }

    const boundingRect = context.getBoundingClientRect()
    const x = (this.mouseOffset.x = evt.pageX - boundingRect.left)
    const y = (this.mouseOffset.y = evt.pageY - boundingRect.top)
    this.renderer.updateModelViewpoint({ x, y })
  }

  private handleTouchMove(evt: TouchEvent) {
    if (this.gyroInputController || this.autoRotateModel) {
      return
    }

    evt.preventDefault()
    evt.stopPropagation()

    const context = this.$refs.context

    if (!(context instanceof HTMLDivElement)) {
      return
    }

    const touch = evt.touches[0]

    const boundingRect = context.getBoundingClientRect()
    const x = (this.mouseOffset.x = touch.pageX - boundingRect.left)
    const y = (this.mouseOffset.y = touch.pageY - boundingRect.top)

    this.renderer.updateModelViewpoint({ x, y })
  }

  private handleResize(evt: UIEvent) {
    this.updateRenderer()
  }

  private updateRenderer() {
    const context = this.$refs.context
    if (!(context instanceof HTMLDivElement)) {
      return
    }
    const panes = Array.from<HTMLDivElement>(context.querySelectorAll('.pane'))
    const positions = panes.map((e: HTMLDivElement) => {
      return {
        x: e.offsetLeft,
        y: e.offsetTop,
      }
    })
    const size = { width: this.itemSize, height: this.itemSize }
    this.renderer.resize(
      context.clientWidth,
      context.clientHeight,
      positions,
      size
    )
  }

  @Watch('gyroInputController')
  private handleGyroInputControllerChange(
    gyroInputController: GyroInputController
  ) {
    this.renderer.gyroInputController = gyroInputController
  }

  @Watch('arts')
  private async handleArtsChange(arts: Art[]) {
    if (
      this.rerenderOnlyActuallyChanges &&
      this.lastRenderedArts.length === arts.length
    ) {
      const notChanged = this.lastRenderedArts.every((o, i) => o === arts[i])
      if (notChanged) {
        return
      }
    }

    this.lastRenderedArts = arts

    this.renderer.setup(arts, {
      rotateMagnificate: this.rotateMagnificate,
      autoRotateModel: this.autoRotateModel,
      itemScale: this.itemScale,
      gyroInputController: this.gyroInputController,
    })

    await this.$nextTick()
    this.updateRenderer()
    this.resetModelViewpoint()
  }

  @Watch('layerAppearances', { deep: true })
  private handleLayerAppearancessChange(layerAppearances: ILayerAppearance[]) {
    for (const [index, appearance] of layerAppearances.entries()) {
      this.renderer.setModelVisibility(0, index, appearance.visible)
    }
  }
}
