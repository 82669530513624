import Vue from 'vue'
import VueGtm from 'vue-gtm'
import VueRouter from 'vue-router'
import Raven from 'raven-js'
import RavenVue from 'raven-js/plugins/vue'
import Icon from 'vue-awesome/components/Icon'
import filters from './filters'
import vueFirestore from './vue-typed-firestore'
import db from './db'

import './gyro-input-controller'
import './vue-awesome-icons'
import './plugins/element'

const dataLinkPlugin = vueFirestore.link({
  expires: 10 * 1000,
  fetch (path) {
    return db.doc(path).get()
  },
})

Raven
  .config('https://4170647517b44774a7092f957ee4d6c3@sentry.io/1204959')
  .addPlugin(RavenVue, Vue)
  .install()

Vue.use(VueRouter)
Vue.use(vueFirestore.stream(db, dataLinkPlugin.dataLink.documentMapper))
Vue.use(dataLinkPlugin)

Vue.component('logo', require('./components/Logo.vue').default)
Vue.component('stored-value', require('./components/StoredValue.vue').default)
Vue.component('inline-loading', require('./components/InlineLoading.vue').default)
Vue.component('icon', Icon)

for (const filterKey of Object.keys(filters)) {
  Vue.filter(filterKey, filters[filterKey])
}

const router = require('./router').default
const lifecycle = require('./app-lifecycle').default

Vue.use(VueGtm, { vueRouter: router })

const App = require('./components/App.vue').default

/* eslint-disable no-new */
new Vue({
  created: lifecycle.created,
  render: (h) => h(App),
  router,
}).$mount('#app')
