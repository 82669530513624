







import Vue, { Component as VueComponent } from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component({
  name: 'inline-loading',
  components: {
  },
})
export default class InlineLoading extends Vue {
  @Prop({ type: String, default: '', required: false })
  append!: string

  @Prop({ type: String, default: '', required: false })
  prepend!: string

  @Prop({ type: String, default: '14px', required: false })
  size!: string
}
