




























import Vue, { Component as VueComponent } from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import device from '../../device'
import { Page } from '../../page'

import ArtList from '../ArtList.vue'

import firebaseApp from '../../firebase-app'

@Component({
  name: 'home',
  components: {
    ArtList,
  },
})
export default class Home extends Page {
  path = '/'
  keyArt = require('../../keyart.json')

  mounted () {
    document.title = 'Layart(α) ― 3Dアートを投稿・鑑賞しよう'

    if (localStorage.getItem('identity-cache')) {
      this.$router.push({ name: 'dashboard' })
    }
  }

  get calculatedArtListStyle () {
    if (device.isMobile) {
      return {
        width: `${window.innerWidth}px`,
        height: `${window.innerHeight}px`,
      }
    } else {
      return {}
    }
  }

  get calculatedItemSize () {
    if (device.isMobile) {
      return Math.min(window.innerWidth, window.innerHeight) * 0.9
    } else {
      return Math.min(800, window.innerWidth / 2)
    }
  }
}
