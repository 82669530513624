import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export interface IRemoteBaseOptions {
  base: string
}

export class RemoteBase {
  base: string
  token?: string
  reauth?: () => Promise<void>

  constructor (options: IRemoteBaseOptions) {
    this.base = options.base
  }

  protected getRequest (path: string, options: AxiosRequestConfig) {
    return this.handleRetry(() => axios.get(path, options))
  }

  protected postRequset (path: string, data: any, options: AxiosRequestConfig) {
    return this.handleRetry(() => axios.post(path, data, options))
  }

  protected handleRetry (process: () => Promise<AxiosResponse>) {
    return process().catch(async (err) => {
      console.log('err.data', err.data)
      if (err.data)  {
        const message = err.data.message
        if (typeof message === 'string') {
          err.message = message
        }
      }
      console.log('err.message', err.message)
      if (err.message.match(/(token has expired)/)) {
        if (this.reauth) {
          await this.reauth()
        }
        return process()
      } else {
        throw err
      }
    })
  }

  protected get genericConfig (): AxiosRequestConfig {
    return {
      baseURL: this.base,
      headers: {
        'Authorization': `Bearer ${this.token}`,
        'Content-Type': 'application/json',
      },
      validateStatus (status) {
        return status >= 200 && status < 300
      },
    }
  }
}
