




































































































import { first } from 'rxjs/operators'
import { Page } from '../../page'
import Component from 'vue-class-component'
import ArtsRenderer, { shared as sharedRenderer } from '../../arts-renderer'
import { ArtData, IArtError, ArtProcessStatus, ArtProcessSubject } from '../../models/art'
import { UserData } from '../../models/user'
import storage from '../../storage'
import identityManager from '../../identity-manager'
import device from '../../device'
import Color from 'color'
import { range } from '../../util'
import db from '../../db'
import remote from '../../remote'
import { LayerData, buildLayers } from '../../models/layer'
import { GyroInputController } from '../../gyro-input-controller'

import '../../vue-typed-firestore/vue'

import ArtList from '../ArtList.vue'
import TweetButton from '../TweetButton.vue'

interface IArtProcess {
  status: ArtProcessStatus
  subject: ArtProcessSubject
}

interface ILayerAppearance {
  visible: boolean
}

const defaultControlType = localStorage.getItem('default-control-type') || 'gyro'

@Component({
  name: 'art-show-page',
  components: {
    ArtList,
    TweetButton,
  },
})
export default class ArtNewPage extends Page {
  path = '/arts/:artId'
  artId = ''
  rerenderOnlyActuallyChanges = false
  art = ArtData.placeholder()
  user = UserData.placeholder()
  loved: boolean = false
  layerAppearances: ILayerAppearance[] = []
  videoSource: string = ''
  processing = false
  retriable = false
  retryProcessing = false
  currentProcess: IArtProcess = { status: 'initiate', subject: 'generic' }
  errors: IArtError[] = []
  deleting = false
  numberOfLoves = 0
  itemSize = 400
  artListStyle: { [key: string]: string | undefined } = {
    opacity: '0',
  }
  controlType = defaultControlType
  lightContent = false
  headerIsVisible = true
  tweetOptions = {
    text: '',
    url: location.href,
    via: 'layartme',
    hashtags: 'layart',
  }

  steps = [ {
      title: '1. 待機',
      matcher (process: IArtProcess) {
        return process.status === 'initiate'
      },
    }, {
      title: '2. データの展開',
      matcher (process: IArtProcess) {
        return process.subject === 'extract'
      },
    }, {
      title: '3. データの格納',
      matcher (process: IArtProcess) {
        return process.subject === 'store-data'
      },
    }, {
      title: '4. 動画のエンコード',
      matcher (process: IArtProcess) {
        return process.subject === 'encode-video'
      },
    }, {
      title: '5. 動画の格納',
      matcher (process: IArtProcess) {
        return process.subject === 'store-video'
      },
    }, {
      title: '6. 仕上げ',
      matcher (process: IArtProcess) {
        return process.subject === 'store-metadata' || process.subject === 'completed'
      },
    },
  ]

  activeStep = 1
  gyroInputController?: GyroInputController

  created () {
    this.gyroInputController = new GyroInputController()
  }

  beforeDestroy () {
    if (this.gyroInputController) {
      this.gyroInputController.unsubscribe()
    }
  }

  get pageClasses () {
    return this.isEmbed ? ['embed'] : device.platformClasses
  }

  get containerTransitionName () {
    return (this.isEmbed || this.isMobile) ? 'fade' : 'slide'
  }

  mounted () {
    this.artId = this.$route.params.artId

    this.$firestream.document<typeof ArtData>(`arts/${this.artId}`).subscribe((artDoc) => {
      const art = artDoc.mapped

      if (art.status !== 'completed') {
        this.retryProcessing = false
        this.processing = true

        setTimeout(() => {
          this.retriable = this.processing
        }, 1000 * 60 * 3)

        this.currentProcess = {
          status: art.status,
          subject: art.subject,
        }
        const step = this.steps.find((s) => s.matcher(art))
        if (art.status === 'failed' && art.errors) {
          this.errors = art.errors
        } else {
          if (step) {
            this.activeStep = this.steps.indexOf(step)
          }
        }
        return
      }

      this.processing = false
      this.retriable = false
      this.errors = []
      const layers = art.layers
      const newLayers = buildLayers(art.layers, art.numberOfLayers)
      this.art = art

      this.$firelink.fetch(art.user.path).then((userSnapshot) => {
        const title = this.pageTitle(art.title, userSnapshot.data()!.name)
        document.title = title
        this.tweetOptions.text = title
      })

      this.lightContent = Color(art.backgroundColor).lightness() > 50.0

      this.layerAppearances = newLayers.map(() => ({ visible: true }))
      this.videoSource = art.videos.main.url

      this.$nextTick().then(async () => {
        this.itemSize = this.calculatedItemSize
        await this.$nextTick()
        this.artListStyle = this.calculatedArtListStyle
        await this.$nextTick()
        this.rerenderOnlyActuallyChanges = true
      })

      const currentUser = this.currentUser
      if (currentUser) {
        this.$firestream.document(`arts/${this.artId}/loves/${currentUser.uid}`).pipe(first()).subscribe((data) => {
          this.loved = data.exists
        })
      }
    })
    this.$firestream.query(`arts/${this.artId}/loves`).subscribe((data) => {
      this.numberOfLoves = data.size
    })
  }

  async handleClickRetryProcessButton () {
    this.retryProcessing = true
    await remote.retryProcessArtArchive({ artId: this.artId })
    this.errors = []
    this.retryProcessing = false
  }

  async handleClickDeleteArtButton () {
    this.deleting = true
    await this.deleteArt()
    this.$router.push({ name: 'art-new-page' })
  }

  async deleteArt () {
    this.$firestream.unsubscribe()
    await db.doc(`arts/${this.artId}`).delete()
  }

  async handlePreferenceCommand (command: string) {
    switch (command) {
      case 'delete':
        this.deleting = true
        await this.deleteArt()
        this.$router.push({ name: 'art-list-page' })
        break
    }
  }

  pageTitle (artTitle: string, username: string) {
    return `[Layart(α)] ${artTitle} by ${username}`
  }

  toggleInfoVisibility () {
    this.headerIsVisible = !this.headerIsVisible
  }

  photoStyleForUser (user: typeof UserData['types']['stored']) {
    return {
      'background-image': `url(${user.photo})`,
    }
  }

  get calculatedItemSize () {
    if (this.isMobile || this.isEmbed) {
      return Math.min(window.innerWidth, window.innerHeight)
    } else {
      return Math.min(800, window.innerHeight - 300)
    }
  }

  get calculatedArtListStyle () {
    if (this.isMobile || this.isEmbed) {
      return {
        width: `${window.innerWidth}px`,
        height: `${window.innerHeight}px`,
      }
    } else {
      return {}
    }
  }

  get currentUser () {
    return identityManager.currentUser
  }

  get noPhotoStyle () {
    return {
      'background-image': 'url(https://abs.twimg.com/sticky/default_profile_images/default_profile.png)',
    }
  }

  async handleLoveButtonClick () {
    if (!this.currentUser) {
      try {
        const login = await this.$confirm('いいね機能を利用するにはログインが必要です。')
        this.$router.push({ name: 'auth' })
      } catch (err) {
        if (err !== 'cancel') {
          throw err
        }
      }
      return
    }

    if (this.art.blank) {
      return
    }

    if (this.loved) {
      this.loved = false
      await remote.deleteLove({ artId: this.art.id })
    } else {
      this.loved = true
      await remote.createLove({ artId: this.art.id })
    }
  }

  async handleControlTypeChange () {
    await this.$nextTick()
    localStorage.setItem('default-control-type', this.controlType)
  }

  get isMobile (): boolean {
    return device.isMobile
  }

  get isEmbed () {
    return 'embed' in this.$route.query
  }

  get isDesktop () {
    return !this.isMobile && !this.isEmbed
  }

  get isTablet () {
    return device.isTablet && !this.isEmbed
  }

  get isOwned () {
    if (this.art.blank || !identityManager.currentUser) {
      return false
    }
    return this.art.user.id === identityManager.currentUser.uid
  }
}
