









import { Page } from '../../page'
import Component from 'vue-class-component'
import identityManager from '../../identity-manager'

import firebaseApp from '../../firebase-app'

@Component({
  name: 'auth',
})
export default class Auth extends Page {
  path = '/auth'
  showLogin = false

  mounted () {
    firebaseApp.auth().getRedirectResult().then(async (result) => {
      if (!result.user) {
        this.showLogin = true
      } else {
        await identityManager.handleChangeAuthState(result.user)
        this.$router.push({ name: 'dashboard' })
      }
    })
  }

  async signInWithTwitter () {
    identityManager.signInWithTwitter()
  }
}
