import firebase from 'firebase/app'
import firebaseApp from './firebase-app'
import remote from './remote'
import db from './db'
import { Subject } from 'rxjs'

interface IIdentityCache {
  token: string
  cacheExpireAt: number
  userInfo: { name: string, photo?: string }
}

export interface IUserInfo {
  name: string
  photo?: string
}

class IdentityManager {
  currentUser?: firebase.User
  currentUserInfoStream = new Subject<IUserInfo>()
  private currentUserInfoInternal: IUserInfo | null = null

  get currentUserInfo (): IUserInfo | null {
    return this.currentUserInfoInternal
  }

  set currentUserInfo (info: IUserInfo | null) {
    this.currentUserInfoInternal = info
    if (info) {
      this.currentUserInfoStream.next(info)
    }
  }

  async handleChangeAuthState (user: firebase.User | null) {
    if (user) {
      this.reauth(user)
    } else {
      this.signOut()
    }
  }

  async signInWithTwitter () {
    const provider = new firebase.auth.TwitterAuthProvider()
    firebaseApp.auth().signInWithRedirect(provider)
  }

  async reauth (user?: firebase.User) {
    if (!user) {
      throw new Error('uset is empty')
    }

    const cacheExpireAt = new Date().getTime() + 10 * 60 * 1000
    const token = await user.getIdToken(true)
    this.currentUser = user
    remote.token = token
    remote.reauth = () => {
      return this.reauth(this.currentUser)
    }
    const providerData = user.providerData[0]

    if (!providerData) {
      return
    }

    const photo = providerData.photoURL ? providerData.photoURL.replace(/_normal\./, '.') : undefined

    const existingUser = await db.collection('users').doc(user.uid).get()

    if (!existingUser.exists) {
      this.updateCurrentUserInfo({
        name: providerData.displayName || undefined,
        photo,
      })
    }

    const userInfo = {
      name: existingUser.exists ? existingUser.data()!.name : (providerData.displayName || 'no name'),
      photo: providerData.photoURL || undefined,
    }

    this.persistentCache({ token, cacheExpireAt, userInfo })
    this.currentUserInfo = userInfo
  }

  async updateCurrentUserInfo (userInfo: Partial<IUserInfo>) {
    const uid = this.currentUser!.uid
    await db.collection('users').doc(uid).set(userInfo, { merge: true })
    this.currentUserInfo = {
      ...this.currentUserInfo!,
      ...userInfo,
    }
  }

  signInWithCache (cache: IIdentityCache) {
    remote.token = cache.token
    this.currentUserInfo = cache.userInfo
  }

  cache (): IIdentityCache | undefined {
    const cacheString = localStorage.getItem('identity-cache')
    if (!cacheString) {
      return undefined
    }
    const cache = JSON.parse(cacheString)
    if (cache.cacheExpireAt > new Date().getTime()) {
      return cache
    }
    return undefined
  }

  async signOut () {
    this.currentUser = undefined
    this.currentUserInfo = null
    localStorage.clear()
    await firebaseApp.auth().signOut()
  }

  private persistentCache (cache: IIdentityCache) {
    localStorage.setItem('identity-cache', JSON.stringify(cache))
  }
}

const manager = new IdentityManager()

export default manager
