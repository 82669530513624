





import Vue, { Component as VueComponent } from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'

@Component({
  name: 'logo',
})
export default class Logo extends Vue {
}
