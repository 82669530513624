import { RemoteBase, IRemoteBaseOptions } from './remote-base'
import config from './config'

export class Remote extends RemoteBase {
  async authorize () {
    return this.getRequest('/authorize', { ...this.genericConfig })
  }

  async fetchLatestNews () {
    return this.getRequest('/news', { ...this.genericConfig })
  }

  async createArt (payload: { ts: number, title: string, backgroundColor: string, description?: string }) {
    return this.postRequset('/arts/create', payload, { ...this.genericConfig })
  }

  async createLove (payload: { artId: string }) {
    return this.postRequset('/loves/create', payload, { ...this.genericConfig })
  }

  async deleteLove (payload: { artId: string }) {
    return this.postRequset('/loves/delete', payload, { ...this.genericConfig })
  }

  async retryProcessArtArchive (payload: { artId: string }) {
    return this.postRequset('/arts/retryProcessArchive', payload, { ...this.genericConfig })
  }
}

const remote = new Remote({
  base: config.remoteBaseUrl,
})

export default remote
