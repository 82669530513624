
import firebase from 'firebase/app'
import config from '../firebase'

import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const firebaseApp = firebase.initializeApp(config, 'layart')
firebaseApp.auth().languageCode = 'ja'

export default firebaseApp
