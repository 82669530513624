import firebaseApp from './firebase-app'
import identityManager from './identity-manager'

export default {
  async created () {
    const identityCache = identityManager.cache()
    if (identityCache) {
      identityManager.signInWithCache(identityCache)
    }
    firebaseApp.auth().onAuthStateChanged((user) => {
      identityManager.handleChangeAuthState(user)
    })
  },
}
