










import Vue, { Component as VueComponent } from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import '../vue-typed-firestore'

import db from '../db'

@Component({
  name: 'stored-value',
})
export default class StoredValue extends Vue {
  @Prop({ type: String, required: true })
  path!: string

  @Prop({ type: String, required: false })
  prop!: string

  @Prop({ type: String, default: 'fade', required: false })
  transition!: string

  @Prop({ type: Boolean, default: true, required: false })
  loading!: boolean

  id = ''
  data: { [key: string]: any } = {}

  loaded = false
  notExists = false

  async mounted () {
    await this.load()
  }

  async load () {
    this.loaded = false
    const snapshot = await this.$firelink.fetch(this.path)
    if (snapshot.exists) {
      this.id = snapshot.id
      this.data = snapshot.data()!
      this.loaded = true
    } else {
      this.notExists = true
    }
  }

  @Watch('path')
  async handlePathUpdate () {
    await this.load()
  }

  @Watch('prop')
  async handlePropUpdate () {
    await this.load()
  }
}
