import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'
import sortBy from 'lodash/sortBy'
import range from 'lodash/range'
import last from 'lodash/last'
import first from 'lodash/first'
import isEqual from 'lodash/isEqual'
import intersection from 'lodash/intersection'

export { minBy, maxBy, sortBy, range, last, first, isEqual, intersection }
