















import Vue, { Component as VueComponent } from 'vue'
import Component from 'vue-class-component'
import { Loading } from 'element-ui'

import Navigation from './Navigation.vue'

import device from '../device'
import identityManager from '../identity-manager'

@Component({
  name: 'app',
  components: {
    Navigation,
  },
})
export default class App extends Vue {
  verified = false
  identityManager = identityManager
  device = device
}
