import Vue, { ComponentOptions } from 'vue'
import { IRoutable } from './router'
import 'element-ui'

export class Page extends Vue implements IRoutable {
  path: string = ''
  authRequired = false
  title: () => string = () => 'Layart'

  // tslint:disable-next-line:no-empty
  nothingToDo = () => {}
}

export interface IPageComponent extends ComponentOptions<Vue> {
  new (): Page
}
