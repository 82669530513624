import { Subject } from 'rxjs'
import { first } from 'rxjs/operators'

export interface IDeviceOrientation {
  beta: number
  gamma: number
  alpha: number
}

export interface IGyroInput {
  x: number
  y: number
}

export class GyroInputController extends Subject<IGyroInput> {
  gyroIsReady = false

  private orientationHandler: (data: IDeviceOrientation) => void

  constructor () {
    super()
    this.orientationHandler = (data) => {
      if (!this.isStopped) {
        this.next({ x: data.gamma, y: data.beta })
      }
    }
    this.pipe(first()).subscribe((data) => {
      if (typeof data.x === 'number') {
        this.gyroIsReady = true
      }
    })
    window.addEventListener('deviceorientation', this.orientationHandler as any, true)
  }

  unsubscribe () {
    super.unsubscribe()
    window.removeEventListener('deviceorientation', this.orientationHandler as any)
  }
}
