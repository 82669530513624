










import Vue, { Component as VueComponent } from 'vue'
import Component from 'vue-class-component'
import { IRoutable } from '../../router'

import identityManager from '../../identity-manager'

@Component({
  name: 'profile',
  components: {
  },
})
export default class Profile extends Vue implements IRoutable {
  path = '/profile'
  authRequired = true

  updating = false
  form = {
    name: '',
  }

  rules = {
    name: [{
      required: true,
      message: '名前を入力して下さい',
    }],
  }

  mounted () {
    document.title = '[Layart(α)] プロフィール編集'
    this.reset()
  }

  private reset () {
    this.form = {
      name: identityManager.currentUserInfo!.name,
    }
  }

  private async onUpdate () {
    this.updating = true
    try {
      await identityManager.updateCurrentUserInfo(this.form)
      this.$message.success('プロフィールが更新されました。')
    } catch (err) {
      this.$message.error(err.message)
    }
    this.updating = false
  }
}
