import JSZip from 'jszip'

export default class Archiver {
  zip: JSZip

  constructor() {
    this.zip = new JSZip()
  }

  async add(name: string, file: Blob) {
    this.zip.file(name, file)
  }

  async addText(name: string, body: string) {
    this.zip.file(name, body)
  }

  async toBlob(): Promise<Blob> {
    return this.zip.generateAsync<'blob'>({ type: 'blob' })
  }
}
