












































import Vue, { Component as VueComponent } from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import firebaseApp from '../firebase-app'
import identityManager from '../identity-manager'
import 'vue-router'

interface INavigationComponent {
  name: string
  label: string
  link: string
  pattern: RegExp
  icon: string
  available: () => boolean
}

@Component({
  name: 'navigation',
})
export default class Navigation extends Vue {
  @Prop({ default: false, type: Boolean })
  mobile!: boolean

  @Prop({ default: false, type: Boolean })
  tablet!: boolean

  get currentUserInfo () {
    return identityManager.currentUserInfo
  }

  private components: INavigationComponent[] = [
    {
      name: 'dashboard',
      label: 'ホーム',
      pattern: /^dashboard$/,
      link: 'dashboard',
      icon: 'el-icon-menu',
      available: () => !!this.currentUserInfo,
    },
    {
      name: 'art-list',
      label: '作品を見る',
      pattern: /^art-/,
      link: 'art-list-page',
      icon: 'el-icon-picture',
      available: () => true,
    },
  ]

  get logoLinkTo () {
    if (this.currentUserInfo) {
      return { name: 'dashboard' }
    } else {
      return '/'
    }
  }

  get embed () {
    return 'embed' in this.$route.query
  }

  get activeIndex (): string | undefined {
    const name = this.$route.name
    if (!name) {
      return undefined
    }
    const component = this.components.find((c) => !!name.match(c.pattern))
    if (component) {
      return (this.components.indexOf(component) + 1).toString()
    }
    return undefined
  }

  handleMenuSelected (index: number) {
    const component = this.components[index - 1]
    this.$router.push({ name: component.link })
  }

  handleProfileButtonClicked () {
    this.$router.push({ name: 'profile' })
  }

  async handleLogoutButtonClicked () {
    await identityManager.signOut()
    this.$router.push({ path: '/' })
  }
}
