export default class ImageConverter {
  async convert (imageFile: File) {
    const originalUrl = URL.createObjectURL(imageFile)
    const originalImage = new Image()
    const size = await new Promise<{ width: number, height: number }>((resolve) => {
      originalImage.onload = () => {
        resolve({
          width: originalImage.width,
          height: originalImage.height,
        })
      }
      originalImage.src = originalUrl
    })
    const longerSide = Math.max(size.width, size.height)
    const estimatedNewSize = Math.pow(2, Math.ceil(Math.log(longerSide) / Math.log(2)))
    const newSize = Math.min(estimatedNewSize, 1024)
    const canvas = document.createElement('canvas')
    const finalScale = newSize / longerSide

    canvas.width = newSize
    canvas.height = newSize

    const scaleSteps: number[] = []
    for (let i = 0; i < 3; i++) {
      const scale = finalScale * Math.pow(2, i)
      if (scale < 0.5) {
        scaleSteps.push(0.5)
      } else {
        scaleSteps.push(scale)
        break
      }
    }

    let target: HTMLImageElement | HTMLCanvasElement = originalImage
    let currentScale = 1.0
    for (const scaleStep of scaleSteps) {
      currentScale *= scaleStep
      const buffer = document.createElement('canvas')
      buffer.width = size.width * currentScale
      buffer.height = size.height * currentScale
      const bufferContext = buffer.getContext('2d')!
      this.smooth(bufferContext)
      bufferContext.scale(scaleStep, scaleStep)
      bufferContext.drawImage(target, 0, 0)
      target = buffer
    }

    const context = canvas.getContext('2d')!
    context.drawImage(
      target,
      (newSize - size.width * finalScale) / 2,
      (newSize - size.height * finalScale) / 2,
      size.width * finalScale,
      size.height * finalScale,
    )

    const blob = await new Promise((resolve, reject) => {
      canvas.toBlob((result) => {
        result ? resolve(result) : reject(new Error('fetch converted image failed'))
      })
    })

    const url = URL.createObjectURL(blob)
    return { url, scale: finalScale, originalUrl }
  }

  private smooth (context: CanvasRenderingContext2D) {
    context.imageSmoothingEnabled = true
  }
}
