import * as THREE from 'three'
import Archiver from './archiver'
import { ArtData } from './models/art'
import { GyroInputController } from './gyro-input-controller'
import { buildLayers } from './models/layer'
import { first } from 'rxjs/operators'

interface IModel {
  mesh: THREE.Mesh
  layers: Layer[]
}

export type Layer = IConcreteLayer | IBlankLayer
type Art = typeof ArtData['types']['blankOrStored'] | typeof ArtData['types']['blankOrLocal']

interface IConcreteLayer {
  blank: false
  mesh: THREE.Mesh
  texture: THREE.Texture
  material: THREE.Material
  geometry: THREE.BufferGeometry
}

interface IBlankLayer {
  blank: true
  geometry: THREE.BufferGeometry
}

interface IPoint {
  x: number
  y: number
}

interface ISize {
  width: number
  height: number
}

interface ISetupOptions {
  rotateMagnificate?: number
  autoRotateModel?: boolean
  backgroundColor?: string
  itemScale?: number
  gyroInputController?: GyroInputController
}

export default class ArtsRenderer {
  casingEnabled = false
  autoRotateModel = false
  rotateMagnificate = 0.3
  gyroInputController?: GyroInputController
  sleeping = false

  private renderer: THREE.WebGLRenderer
  private models: IModel[] = []
  private camera?: THREE.OrthographicCamera
  private scene?: THREE.Scene
  private globalMesh?: THREE.Mesh
  private inputBasePoint: IPoint = { x: 0, y: 0 }
  private inputPoint: IPoint = { x: 0, y: 0 }
  private inputPointRaw: IPoint = { x: 0, y: 0 }

  private fovScale = 0.002
  private itemScale = 1.0
  private recording = false

  private modelViewpointRaw: IPoint = { x: 0, y: 0 }
  private modelViewpoint: IPoint = { x: 0, y: 0 }
  private targetPositions: IPoint[] = []
  private targetSize: ISize = { width: 1, height: 1 }
  private inRenderingLoop = false

  constructor () {
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
    this.renderer.setPixelRatio(window.devicePixelRatio || 1)
  }

  startRenderingLoop () {
    if (this.inRenderingLoop) {
      return
    }
    this.inRenderingLoop = true
    this.renderingLoop()
  }

  renderingLoop () {
    requestAnimationFrame(() => this.renderingLoop())
    this.render()
  }

  updateModelViewpoint (viewpoint: IPoint) {
    if (this.recording) {
      return
    }
    this.modelViewpointRaw = viewpoint
  }

  resetControlInputBasePoint () {
    this.inputBasePoint = this.inputPointRaw
  }

  async capture (duration: number, archiver: Archiver, progress?: (progress: number) => void): Promise<Archiver> {
    this.recording = true
    const frames = duration / 1000 * 30
    for (let frame = 0; frame < frames; frame++) {
      this.renderForRecording(frame, frames)
      const blob = await new Promise<Blob>((resolve) => {
        this.renderer.domElement.toBlob((result) => {
          resolve(result!)
        })
      })
      archiver.add(`video-frames/${frame}.png`, blob)
      if (progress) {
        progress((frame + 1) / frames)
      }
    }
    this.recording = false
    return archiver
  }

  renderForRecording (frame: number, totalFrames: number) {
    if (!this.camera || !this.scene) {
      return
    }
    this.rotateModel(Math.PI * 2 / totalFrames * frame, 0.4, 0.1)
    this.renderer.render(this.scene, this.camera)
  }

  rotateModel (angle: number, scaleX = 0.3, scaleY = 0.3) {
    for (const [i, model] of this.models.entries()) {
      model.mesh.rotation.set(
        Math.cos(angle + Math.PI * 0.25) * scaleY,
        Math.sin(angle) * scaleX,
        0,
      )
    }
  }

  setModelVisibility (modelIndex: number, layerIndex: number, visible: boolean) {
    const layer = this.models[modelIndex].layers[layerIndex]
    if (layer.blank === false) {
      layer.mesh.visible = visible
    }
  }

  render () {
    if (this.sleeping) {
      return
    }

    if (!this.camera || !this.scene) {
      return
    }

    if (this.gyroInputController && this.gyroInputController.gyroIsReady) {
      this.adjustControlInput()
      for (const [i, model] of this.models.entries()) {
        const m = 0.02
        const y = (this.inputPoint.y) * m
        const x = (this.inputPoint.x) * m
        model.mesh.rotation.set(y, x, 0)
      }
    } else if (!this.autoRotateModel) {
      this.adjustModelViewpoint()
      const mvp = this.modelViewpoint
      const ts = this.targetSize
      const m = this.rotateMagnificate
      for (const [i, model] of this.models.entries()) {
        const position = this.targetPositions[i]
        const dx = (mvp.x - position.x - ts.width  / 2) / ts.width
        const dy = (mvp.y - position.y - ts.height / 2) / ts.height
        const angle = Math.atan2(dy, dx)
        const distance = Math.max(-0.5, Math.min(0.5, Math.sqrt(dx * dx + dy * dy)))
        const adx = Math.cos(angle) * m * distance
        const ady = Math.sin(angle) * m * distance
        model.mesh.rotation.set(ady, adx, 0)
      }
    } else if (this.autoRotateModel) {
      const m = this.rotateMagnificate
      const ms = new Date().getTime()
      const loopDuration = 1000 * 10
      const angle = (ms % loopDuration) / loopDuration * Math.PI * 2
      this.rotateModel(angle, m, 0)
    }

    this.renderer.render(this.scene, this.camera)
  }

  get domElement (): HTMLCanvasElement {
    return this.renderer.domElement
  }

  resize (width: number, height: number, positions: IPoint[], size: ISize) {
    this.targetPositions = positions
    this.targetSize = size

    this.renderer.setSize(width, height)

    if (!this.camera) {
      return
    }

    this.camera.left   =  -width  * this.fovScale / 2.0
    this.camera.right  =   width  * this.fovScale / 2.0
    this.camera.top    =   height * this.fovScale / 2.0
    this.camera.bottom = - height * this.fovScale / 2.0

    this.camera.updateProjectionMatrix()

    for (const [i, model] of this.models.entries()) {
      model.mesh.position.x =  (-width  / 2 + size.width  / 2 + positions[i].x) * this.fovScale
      model.mesh.position.y = -(-height / 2 + size.height / 2 + positions[i].y) * this.fovScale

      const meshScale = size.width / 500
      model.mesh.scale.set(meshScale, meshScale, meshScale)
    }
  }

  adjustModelViewpoint () {
    if (this.recording) {
      return
    }
    this.adjustPoint(this.modelViewpoint, this.modelViewpointRaw)
  }

  adjustControlInput () {
    const maximum = 20.0
    const adjust = (v: number) => {
      return Math.min(maximum, Math.max(-maximum, v))
    }
    const to = {
      x: adjust(this.inputPointRaw.x - this.inputBasePoint.x),
      y: adjust(this.inputPointRaw.y - this.inputBasePoint.y),
    }
    this.adjustPoint(this.inputPoint, to, 0.3)
  }

  adjustPoint (point: IPoint, to: IPoint, sensitivity = 0.1) {
    const dx = to.x - point.x
    const dy = to.y - point.y
    const angle = Math.atan2(dy, dx)
    const distance = Math.sqrt(dx * dx + dy * dy)
    const mx = Math.cos(angle) * distance * 0.05
    const my = Math.sin(angle) * distance * 0.05
    point.x += mx
    point.y += my
  }

  setup (arts: Art[], options?: ISetupOptions) {
    this.camera = new THREE.OrthographicCamera(-1, 1, -1, 1, -10, 100)
    this.scene = new THREE.Scene()
    this.globalMesh = new THREE.Mesh()
    this.scene.add(this.globalMesh)

    if (options) {
      if (options.rotateMagnificate) {
        this.rotateMagnificate = options.rotateMagnificate
      }
      if (options.backgroundColor) {
        this.scene.background = new THREE.Color(options.backgroundColor)
      }
      if (options.itemScale) {
        this.itemScale = options.itemScale
      }
      if (options.gyroInputController) {
        this.gyroInputController = options.gyroInputController
        this.gyroInputController.pipe(first()).subscribe((value) => {
          this.inputBasePoint = value
        })
        this.gyroInputController.subscribe((value) => {
          this.inputPointRaw = value
        })
      } else {
        this.gyroInputController = undefined
      }
      this.autoRotateModel = !!options.autoRotateModel
    }

    const column = 4

    const textureLoader = new THREE.TextureLoader()
    const models: IModel[] = []

    for (const [artIndex, art] of arts.entries()) {
      const x = artIndex % column
      const y = Math.floor(artIndex / column)

      const modelMesh = new THREE.Mesh()

      if (art.blank === true) {
        continue
      }

      const layers = buildLayers(art.layers, art.layers.length).map<Layer>((layer, index) => {
        const geometry = new THREE.PlaneBufferGeometry(1, 1, 10, 10)
        if (layer.blank === false) {
          const texture = textureLoader.load(layer.url)
          const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true })
          material.alphaTest = 0.5
          const mesh = new THREE.Mesh(geometry, material)
          mesh.scale.x = this.itemScale
          mesh.scale.y = this.itemScale
          modelMesh.add(mesh)
          mesh.position.z = 0.15 - index * 0.1
          return { geometry, texture, material, mesh, blank: false }
        }
        return { geometry, blank: true }
      })

      const model: IModel = {
        mesh: modelMesh,
        layers,
      }

      this.globalMesh.add(model.mesh)

      if (this.casingEnabled) {
        for (const wall of [false, true]) {
          for (const offset of [1, -1]) {
            const thickness = 0.02
            const length = 1.02
            const depth = 1.5
            const width = wall ? thickness : length
            const height = wall ? length : thickness

            const casingGeometry = new THREE.BoxGeometry(width, height, depth, 10, 10, 10)
            const casingMaterial = new THREE.MeshLambertMaterial({ color: 0xaaaaaa })
            const casingMesh = new THREE.Mesh(casingGeometry, casingMaterial)

            if (wall) {
              casingMesh.position.x += 0.5 * offset
            } else {
              casingMesh.position.y += 0.5 * offset
            }

            casingMesh.position.z = -1.0
            model.mesh.add(casingMesh)
          }
        }
      }

      models.push(model)
    }
    this.models = models

    const baseLight = new THREE.AmbientLight(0xffffff, 0.2)
    this.scene.add(baseLight)

    const light = new THREE.SpotLight(0xffffff, 0.5)
    light.position.x = 0
    light.position.y = 0
    light.position.z = 3
    this.scene.add(light)

    this.camera.position.z = 0
    this.renderer.setClearColor(0x000000, 0)
  }
}

export const shared = new ArtsRenderer()
