export interface IConfig {
  remoteBaseUrl: string
}

const config: IConfig = {
  remoteBaseUrl: 'https://layart.me/api',
  ...((window as any).config || {}),
}

export default config
