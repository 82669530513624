import { ModelFactory } from '../vue-typed-firestore'
import { range } from '../util'

export const LayerData = ModelFactory<{
  url: string,
  scale: number,
}, {
}, {
}>()

interface IBlankLayer {
  blank: true
}

interface ILayer {
  blank: false
  id: string
  url: string
  scale: number
}

interface ILocalLayer {
  url: string
  scale: number
}

interface ILayerEntity {
  index: number
  locations: {
    thumbnails: Array<{ size: number, url: string }>
  }
}

type LayerBuildInput = ILocalLayer | ILayerEntity | ILayer

export function buildLayers (layerEntities: LayerBuildInput[], numberOfLayers: number, thumbnailSize = 1024): Array<IBlankLayer | ILayer> {
  if (layerEntities.every((layer) => 'url' in layer)) {
    return layerEntities as ILayer[]
  }

  const newLayers: Array<IBlankLayer | ILayer> = range(0, numberOfLayers).map<IBlankLayer>(() => {
    return { blank: true }
  })

  for (const layer of layerEntities) {
    if ('locations' in layer) {
      const thumbnail = layer.locations.thumbnails.find((t) => t.size === thumbnailSize)!
      newLayers[layer.index] = {
        id: layer.index.toString(),
        blank: false,
        url: thumbnail.url,
        scale: 1.0,
      }
    }
  }

  return newLayers
}
