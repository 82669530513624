



















































































































import { Page } from '../../page'
import Component from 'vue-class-component'
import { Subject } from 'rxjs'
import { first, filter } from 'rxjs/operators'
import { LayerData } from '../../models/layer'
import ArtsRenderer, { shared as sharedRenderer } from '../../arts-renderer'
import { ArtData } from '../../models/art'
import storage from '../../storage'
import identityManager from '../../identity-manager'
import db from '../../db'
import remote from '../../remote'
import ImageConverter from '../../image-converter'
import { range, last, intersection, isEqual, sortBy } from '../../util'
import Archiver from '../../archiver'
import { Form } from 'element-ui'

import ArtList from '../ArtList.vue'

interface ILayer {
  url?: string
  scale?: number
}

interface IImage {
  name: string
  url: string
  scale: number
  originalUrl: string
}

interface IProgressStep {
  finished: boolean
  progress: number
}

interface IProcessingProgress {
  build: IProgressStep
  record: IProgressStep
  upload: IProgressStep
}

type Art = typeof ArtData['types']['blankOrLocal']
type Layer = typeof LayerData['types']['blankOrLocal']

@Component({
  name: 'art-new-page',
  components: {
    ArtList,
  },
})
export default class ArtNewPage extends Page {
  path = '/arts/new'
  authRequired = true
  activeStepEmitter = new Subject<number>()
  hintPopupForLayerStepIsVisible = false

  art: Art = {
    blank: true,
  }
  backgroundColor = '#222222'
  images: IImage[] = []
  layers: ILayer[] = []
  numberOfLayers = 4
  activeStep = 1
  imageProcessing = false
  draggingToImageDropzone = false
  form = {
    title: (process.env.NODE_ENV === 'development') ? 'test' : '',
    description: '',
  }
  formRules = {
    title: [
      { required: true, message: 'Please input title', trigger: 'change' },
    ],
    description: [
    ],
  }
  renderer: ArtsRenderer = sharedRenderer
  uploading = false
  processingProgress: IProcessingProgress = this.initialProcessingProgress()
  lisenceAgreed = false

  // hooks

  async mounted () {
    document.title = '[Layart(α)] 新規投稿'
    this.layers = range(0, this.numberOfLayers).map(() => ({}))
  }

  // general

  setStep (step: number) {
    this.activeStep = step
    this.activeStepEmitter.next(step)
  }

  stepButtonClass (step: number) {
    return step === this.activeStep ? ['active'] : []
  }

  // step: images

  handleClickAddImageButton (event: MouseEvent) {
    event.preventDefault()
    event.stopPropagation()
    const input = this.$refs.fileInput
    if (input instanceof HTMLInputElement) {
      input.click()
    }
  }

  async handleChangeImageFileInput (event: Event) {
    event.preventDefault()
    event.stopPropagation()
    const input = event.target
    if (!(input instanceof HTMLInputElement)) {
      return
    }
    if (!input.files) {
      return
    }
    const files = Array.from(input.files)
    input.value = ''
    await this.addImageFiles(files)
  }

  async addImageFiles (files: File[]) {
    const converter = new ImageConverter()
    const images: IImage[] = []
    this.imageProcessing = true
    for (const file of files) {
      if (file.type !== 'image/png') {
        const filetype = last(file.type.split('/'))!.toUpperCase()
        this.$message.error(`layer image must be PNG. "${file.name}" is ${filetype}`)
        return
      }
      const name = file.name
      const converted = await converter.convert(file)
      images.push({ name, ...converted })
    }
    this.imageProcessing = false
    this.images = this.images.concat(images)
    this.autoRegisterLayersFromImages(images)
    this.$message.success(`${images.length} 枚の画像が登録されました。`)
    this.hintPopupForLayerStepIsVisible = true
    this.activeStepEmitter.pipe(
      first(),
    ).subscribe(() => {
      this.hintPopupForLayerStepIsVisible = false
    })
  }

  autoRegisterLayersFromImages (images: IImage[]) {
    if (images.length === this.numberOfLayers) {
      for (const [i, image] of sortBy(images, (img: IImage) => img.name).entries()) {
        this.$set(this.layers, i, {
          scale: image.scale,
          url: image.url,
        })
      }
      this.activeStepEmitter.pipe(
        filter((step) => step === 2),
        first(),
      ).subscribe(() => {
        this.$message.info('ファイル名から自動的にレイヤーが配置されました。')
      })
    }
    this.updateArtLayers()
  }

  handleDragOverOnImageDropzone (event: DragEvent) {
    event.preventDefault()
  }

  async handleFileDropToImageDropzone (event: DragEvent) {
    event.preventDefault()
    event.stopPropagation()
    this.draggingToImageDropzone = false
    await this.addImageFiles(Array.from(event.dataTransfer ? event.dataTransfer.files : []))
  }

  handleClickDeleteImageButton (index: number) {
    this.images.splice(index, 1)
  }

  // step: layers

  layerContentStyle (layer: ILayer) {
    return {
      ...(layer.url ? {
        'background-image': `url(${layer.url})`,
      } : {}),
    }
  }

  handleClickLayerImageOnPicker (layer: ILayer, image: IImage) {
    layer.url = image.url
    layer.scale = image.scale
    this.updateArtLayers()
  }

  updateArtLayers () {
    const layers = this.layers.map<Layer>((layer, index) => {
      const url = layer.url
      if (!url) {
        return { blank: true }
      }
      const scale = layer.scale || 1.0
      return { blank: false, id: index.toString(), url, scale }
    })
    this.$set(this, 'art', { ...this.art, layers, blank: false })
  }

  handleClickDeleteLayerButton (index: number) {
    this.$set(this.layers, index, {})
    this.updateArtLayers()
  }

  handleChangeBackgroundColorPickerActiveValue (color: string) {
    const units: string[] = Array.from(color.match(/\d+/g)!)
    const code = `#${units.map((c) => parseInt(c, 10).toString(16)).join('')}`
    this.backgroundColor = code
  }

  // step: upload

  async handleClickSubmitButton () {
    try {
      await (this.$refs.form as Form).validate()
    } catch (err) {
      return
    }

    const title = this.form.title
    const description = this.form.description

    this.processingProgress = this.initialProcessingProgress()
    this.uploading = true

    await this.$nextTick()

    const size = 600
    const width = 1056 / window.devicePixelRatio
    const height = 594 / window.devicePixelRatio
    const x = (width - size) / 2
    const y = (height - size) / 2

    this.renderer.setup([this.art], { backgroundColor: this.backgroundColor })
    this.renderer.resize(width, height, [{ x, y }], { width: size, height: size })

    const ts = new Date().getTime()

    const archiver = new Archiver()

    await this.addLayerImagesToArchive(archiver)
    await this.addVideoFramesToArchive(archiver)
    await this.uploadOriginalImages(ts)
    await this.uploadArchive(archiver, ts)

    this.setStep(4)
    try {
      const art = (await remote.createArt({ ts, title, backgroundColor: this.backgroundColor, description })).data
      this.$router.push({ name: 'art-show-page', params: { artId: art.id } })
    } catch (err) {
      console.log(err)
      this.$message.error(err.message)
      throw err
    }

    this.uploading = false
  }

  async addLayerImagesToArchive (archiver: Archiver) {
    for (const [index, layer] of this.layers.entries()) {
      if (layer.url) {
        const image = this.images.find((img) => img.url === layer.url)
        if (!image) {
          continue
        }
        const layerImageResponse = await fetch(layer.url)
        archiver.add(`layers/${index + 1}.png`, await layerImageResponse.blob())
      }
      this.processingProgress.build.progress = (index + 1) / this.numberOfLayers
    }
    this.processingProgress.build.finished = true
  }

  async addVideoFramesToArchive (archiver: Archiver) {
    await new Promise((resolve) => setTimeout(resolve, 100))
    await this.renderer.capture(5000, archiver, (progress) => {
      this.processingProgress.record.progress = progress * 0.95
    })
    this.processingProgress.record.finished = true
  }

  async uploadOriginalImages (ts: number) {
    const uid = identityManager.currentUser!.uid
    const archiver = new Archiver()
    for (const [index, layer] of this.layers.entries()) {
      if (layer.url) {
        const image = this.images.find((img) => img.url === layer.url)
        if (!image) {
          continue
        }
        const imageResponse = await fetch(image.originalUrl)
        archiver.add(`images/${index + 1}.png`, await imageResponse.blob())
      }
    }
    const archive = await archiver.toBlob()
    const objectRef = storage.ref(`users/${uid}/original-images/${ts}.zip`)
    const metadata = {
      ts: ts.toString(),
    }
    const uploadTask = objectRef.put(archive, { customMetadata: metadata })
    uploadTask.on('state_changed', (snapshot: any) => {
      const progress = snapshot.bytesTransferred / snapshot.totalBytes
      this.processingProgress.upload.progress = progress * 0.5
    })
    const uploadResult = await uploadTask
  }

  async uploadArchive (archiver: Archiver, ts: number) {
    const uid = identityManager.currentUser!.uid
    const archive = await archiver.toBlob()
    const objectRef = storage.ref(`users/${uid}/temporary/art-archive.zip`)
    const metadata = {
      ts: ts.toString(),
    }
    const uploadTask = objectRef.put(archive, { customMetadata: metadata })
    uploadTask.on('state_changed', (snapshot: any) => {
      const progress = snapshot.bytesTransferred / snapshot.totalBytes
      this.processingProgress.upload.progress = (1.0 + progress) * 0.5
    })
    const uploadResult = await uploadTask
    this.processingProgress.upload.finished = true
  }

  initialProcessingProgress (): IProcessingProgress {
    return {
      build: {
        finished: false,
        progress: 0,
      },
      record: {
        finished: false,
        progress: 0,
      },
      upload: {
        finished: false,
        progress: 0,
      },
    }
  }
}
