export class Device {
  clientType: 'mobile' | 'desktop'

  constructor () {
    this.clientType = navigator.userAgent.match(/Phone|Pod|Android/) ? 'mobile' : 'desktop'
  }

  get isMobile () {
    return this.clientType === 'mobile'
  }

  get isTablet () {
    return !this.isMobile && /iP(hone|od|ad)|Android/.test(navigator.platform)
  }

  get platformClasses () {
    return [this.clientType, ...(this.isTablet ? ['tablet'] : [])]
  }
}

const device = new Device()

export default device
