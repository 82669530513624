






import { Page } from '../../page'
import Component from 'vue-class-component'

import device from '../../device'

import LatestArtList from '../LatestArtList.vue'

@Component({
  name: 'art-list-page',
  components: {
    LatestArtList,
  },
})
export default class ArtListPage extends Page {
  path = '/arts'
  device = device

  mounted () {
    document.title = '[Layart(α)] 投稿された作品の一覧'
  }
}
