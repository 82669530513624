import moment from 'moment'

import 'moment/locale/ja'

moment.locale('ja')

function timeAgoInWords(date: string | number) {
  return moment(date).fromNow()
}

function format(date: string | number, f: string) {
  return moment(date).format(f)
}

const filters: { [key: string]: any } = {
  format,
  timeAgoInWords,
}

export default filters
