















import Vue from 'vue'
import Component from 'vue-class-component'
import { IRoutable } from '../../router'

import LatestArtList from '../LatestArtList.vue'
import NewsTicker from '../NewsTicker.vue'

import device from '../../device'

@Component({
  name: 'dashboard',
  components: {
    LatestArtList,
    NewsTicker,
  },
})
export default class Dashboard extends Vue implements IRoutable {
  path = '/dashboard'
  device = device

  mounted () {
    document.title = '[Layart(α)] ホーム'
  }
}
