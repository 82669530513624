import remote from './remote'

export interface IArticle {
  title: string
  url: string
  timestamp: number
}

interface INewsItem {
  title: string
  link: string
  pubDate: string
}

export class NewsFetcher {
  private articles: IArticle[] = []

  async fetch () {
    const data = (await remote.fetchLatestNews()).data
    this.articles = data.items.map((item: INewsItem) => {
      return {
        title: item.title,
        url: item.link,
        timestamp: new Date(item.pubDate).getTime(),
      }
    })
    return this.articles
  }
}

const fetcher = new NewsFetcher()

export default fetcher
